<template>
    <div class="main-box">
        <a-form :label-col="labelCol" :wrapper-col="wrapperCol">
            <div v-if="step1">
                <div class="ec-box">
                    <div class="title">
                        <p>个体认证-主体信息</p>
                    </div>
                    <div class="content">
                        <div class="con-item">
                            <a-form-item label="个体工商户名称">
                                <a-input v-model:value="individualName" placeholder="请输入个体工商户名称"/>
                            </a-form-item>
                        </div>
                        <div class="con-item">
                            <a-form-item label="统一社会信用代码">
                                <a-input v-model:value="creditCode" placeholder="请输入统一社会信用代码" />
                            </a-form-item>
                        </div>
                        <div class="con-item">
                            <a-form-item label="组成形式">
                                <a-input v-model:value="organization" placeholder="请选择组成形式" />
                            </a-form-item>
                        </div>
                        <div class="con-item">
                            <a-form-item label="注册日期">
                                <a-date-picker v-model:value="zhuceriqi" :format="dateFormat" @change="onPickRegDate" style="width: 100%" placeholder="请选择注册日期"/>
                            </a-form-item>
                        </div>
                        <div class="con-item">
                            <a-form-item label="经营场所">
                                <a-input v-model:value="premises" placeholder="请输入经营场所" />
                            </a-form-item>
                        </div>
                        <div class="con-item">
                            <a-form-item label="发照机关">
                                <a-input v-model:value="authority" placeholder="请输入发照机关" />
                            </a-form-item>
                        </div>
                        <div class="con-item">
                            <a-form-item label="经营范围及方式">
                                <a-input v-model:value="businessScope" placeholder="请输入经营范围及方式" />
                            </a-form-item>
                        </div>
                        <div class="con-item">
                            <a-form-item label="登记机关所在区域">
                                <a-cascader v-model:value="area" :options="options" placeholder="请选择登记机关所在区域" />
                            </a-form-item>
                        </div>
                        <div class="con-item">
                            <a-form-item label="类型">
                                <a-select v-model:value="individualType" placeholder="请选择类型">
                                    <a-select-option value="1">类型1</a-select-option>
                                    <a-select-option value="2">类型2</a-select-option>
                                </a-select>
                            </a-form-item>
                        </div>
                        <div class="con-item">
                            <a-form-item label="是否具有抵质押物">
                                <!-- <a-radio-group v-model:value="limits">
                                    <a-radio value="1">是</a-radio>
                                    <a-radio value="2">否</a-radio>
                                </a-radio-group> -->
                                <a-input v-model:value="limits" placeholder="请输入是否具有抵质押物" />
                            </a-form-item>
                        </div>
                        <div class="con-item">
                            <a-form-item label="简述抵质押物">
                                <a-textarea v-model:value="limitsInfo" placeholder="请简述抵质押物" />
                            </a-form-item>
                        </div>
                    </div>
                </div>
                <div class="ec-box">
                    <div class="title">
                        <p>个体认证-人员信息</p>
                    </div>
                    <div class="content">
                        <div class="con-item">
                            <a-form-item label="经营者姓名">
                                <a-input v-model:value="operatorName" placeholder="请输入经营者姓名"/>
                            </a-form-item>
                        </div>
                        <div class="con-item">
                            <a-form-item label="经营者身份证号码">
                                <a-input v-model:value="operatorCard" placeholder="请输入经营者身份证号码"/>
                            </a-form-item>
                        </div>
                        <div class="con-item">
                            <a-form-item label="经营者手机号码">
                                <a-input v-model:value="operatorPhone" placeholder="请输入经营者手机号码"/>
                            </a-form-item>
                        </div>
                        <div class="con-item">
                            <a-form-item label="经营者邮箱">
                                <a-input v-model:value="operatorEmail" placeholder="请输入经营者邮箱"/>
                            </a-form-item>
                        </div>
                        <!-- <div class="con-item con-item-vercode">
                            <a-form-item label="短信验证码">
                                <a-input v-model:value="operatorVercode" />
                                <a-button>获取短信验证码</a-button>
                            </a-form-item>
                        </div> -->
                    </div>
                </div>
                <div class="ec-box">
                    <div class="contents">
                        <a-button @click="onNext">下一步</a-button>
                    </div>
                </div>
            </div>
            <div v-if="step2">
                <div class="ec-box">
                    <div class="title">
                        <p>个体认证-附件信息</p>
                    </div>
                    <div class="content">
                        <div class="con-item-download">
                            <a> 个体工商户信息查询授权书模板下载 </a>
                        </div>
                        <div class="con-item con-item-upload">
                            <a-upload
                                name="file"
                                :action="$uploadApi"
                                :data="{ FFILE: fileList1, FILE_PROPERTY: '附件', FILE_NAME: fileListName1, ENTERPRISE_ID: enterprise_id }"
                                :headers="headers"
                                @change="onUpload"
                                :before-upload="beforeUpload1"
                            >
                                <a-button>
                                    <upload-outlined></upload-outlined>
                                    请上传个体工商户信息查询授权书...
                                </a-button>
                            </a-upload>
                        </div>
                        <div class="con-item con-item-upload">
                            <a-upload
                                name="file2"
                                :action="$uploadApi"
                                :data="{ FFILE: fileList2, FILE_PROPERTY: '附件', FILE_NAME: fileListName2, ENTERPRISE_ID: enterprise_id }"
                                :headers="headers"
                                @change="onUpload"
                                :before-upload="beforeUpload2"
                            >
                                <a-button>
                                    <upload-outlined></upload-outlined>
                                    请上传银行账务流水...
                                </a-button>
                            </a-upload>
                        </div>
                    </div>
                </div>
                <div class="ec-box">
                    <div class="title">
                        <p>个体认证-证件信息</p>
                    </div>
                    <div class="content">
                        <div class="con-item con-item-uploadimg">
                            <a-upload
                                list-type="picture-card"
                                :action="$uploadApi"
                                :data="{ FFILE: fileListimg1, FILE_PROPERTY: '证件', FILE_NAME: fileListImgName1, ENTERPRISE_ID: enterprise_id }"
                                :headers="headers"
                                @change="onUpload"
                                :before-upload="beforeUpload3"
                            >
                                <div v-if="fileListimg1.length < 1">
                                    <plus-outlined />
                                    <div class="ant-upload-text">请上传身份证正面</div>
                                </div>
                            </a-upload>
                        </div>
                        <div class="con-item con-item-uploadimg">
                            <a-upload
                                list-type="picture-card"
                                :action="$uploadApi"
                                :data="{ FFILE: fileListimg2, FILE_PROPERTY: '证件', FILE_NAME: fileListImgName2, ENTERPRISE_ID: enterprise_id }"
                                :headers="headers"
                                @change="onUpload"
                                :before-upload="beforeUpload4"
                            >
                                <div v-if="fileListimg2.length < 1">
                                    <plus-outlined />
                                    <div class="ant-upload-text">请上传身份证反面</div>
                                </div>
                            </a-upload>
                        </div>
                        <div class="con-item con-item-uploadimg">
                            <a-upload
                                list-type="picture-card"
                                :action="$uploadApi"
                                :data="{ FFILE: fileListimg3, FILE_PROPERTY: '证件', FILE_NAME: fileListImgName3, ENTERPRISE_ID: enterprise_id }"
                                :headers="headers"
                                @change="onUpload"
                                :before-upload="beforeUpload5"
                            >
                                <div v-if="fileListimg3.length < 1">
                                    <plus-outlined />
                                    <div class="ant-upload-text">请上传营业执照</div>
                                </div>
                            </a-upload>
                        </div>
                    </div>
                </div>
                <div class="ec-box">
                    <div class="contents">
                        <a-form-item>
                            <a-checkbox-group @change="onAgree">
                                <a-checkbox value="1" name="type" >我已阅读并同意</a-checkbox>
                                <a>《 个体工商户信息查询授权书 》</a>及<a>《 征信服务授权书 》</a>
                            </a-checkbox-group>
                        </a-form-item>
                        <a-button @click="onSubmit">确认提交</a-button>
                    </div>
                </div>
            </div>
         </a-form>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import options from '@/assets/cityOptions'
import { UploadOutlined, PlusOutlined } from '@ant-design/icons-vue'
import { mapGetters } from 'vuex'

export default defineComponent({
    components: {
        UploadOutlined,
        PlusOutlined
    },
    computed: {
        ...mapGetters(['userId'])
    },
    setup() {
        return {
            labelCol: {
                span: 6
            },
            wrapperCol: {
                span: 16
            },
            options,
            headers: {
                authorization: 'authorization-text'
            }
        }
    },
    data () {
        return {
            individualName: '',
            creditCode: '',
            organization: undefined,
            zhuceriqi: undefined,
            premises: '',
            authority: '',
            businessScope: '',
            area: [],
            individualType: undefined,
            limits: '',
            limitsInfo: '',
            operatorName: '',
            operatorCard: '',
            operatorPhone: '',
            operatorEmail: '',
            agree: [],
            fileList1: [],
            fileList2: [],
            fileListName1: '',
            fileListName2: '',
            fileListimg1: [],
            fileListimg2: [],
            fileListimg3: [],
            fileListImgName1: '',
            fileListImgName2: '',
            fileListImgName3: '',
            enterprise_id: '',
            step1: true,
            step2: false,
            dateFormat: 'YYYY-MM-DD',
            isAgree: false
        }
    },
    methods: {
        onUpload (info) {
            if (info.file.response) {
                if (info.file.response.result === 'success') {
                    this.$message.success('上传成功！')
                } else {
                    this.$message.error('上传失败！')
                }
            }
        },
        beforeUpload1 (e) {
            this.fileListName1 = e.name
            this.fileList1 = e
        },
        beforeUpload2 (e) {
            this.fileListName2 = e.name
            this.fileList2 = e
        },
        beforeUpload3 (e) {
            this.fileListImgName1 = e.name
            this.fileListimg1 = e
        },
        beforeUpload4 (e) {
            this.fileListImgName2 = e.name
            this.fileListimg2 = e
        },
        beforeUpload5 (e) {
            this.fileListImgName3 = e.name
            this.fileListimg3 = e
        },
        onPickRegDate (date, dateString) {
            this.zhuceriqi = dateString
        },
        isValid () {
            if (this.individualName === '') {
                this.$message.info('请输入个体工商户名称')
                return false
            } else if (this.creditCode === '') {
                this.$message.info('请输入统一社会信用代码')
                return false
            } else if (this.organization === undefined) {
                this.$message.info('请输入组成形式')
                return false
            } else if (this.premises === '') {
                this.$message.info('请输入经营场所')
                return false
            } else if (this.area.length == 0) {
                this.$message.info('请选择登记机关所在区域')
                return false
            } else if (this.individualType === undefined) {
                this.$message.info('请选择类型')
                return false
            } else if (this.limits === '') {
                this.$message.info('请输入是否具有抵质押物')
                return false
            } else if (this.limitsInfo === '') {
                this.$message.info('请输入简述抵质押物')
                return false
            } else if (this.operatorName === '') {
                this.$message.info('请输入经营者姓名')
                return false
            } else if (this.operatorCard === '') {
                this.$message.info('请输入经营者身份证号')
                return false
            } else if (this.operatorPhone === '') {
                this.$message.info('请输入经营者手机号码')
                return false
            } else if (this.operatorEmail === '') {
                this.$message.info('请输入经营者邮箱')
                return false
            } else if (this.zhuceriqi === '') {
                this.$message.info('请选择注册日期')
                return false
            } else {
                return true
            }
        },
        onNext () {
            let isValid = this.isValid()
            if (isValid) {
                this.$store.dispatch('getEnterPriseAuth', {
                    ENTERPRISE_NAME: this.individualName,
                    CREDIT_CODE: this.creditCode,
                    INDUSTRY_TYPE: this.industryType,
                    ENTERPRISE_TYPE: this.enterpriseType,
                    REGISAUTH_PROVINCE: this.area[0],
                    REGISAUTH_CITY: this.area[1],
                    REGISAUTH_DISTRICT: this.area[2] ? this.area[2] : '',
                    LEGAL_PERSON_NAME: this.operatorName,
                    CERTIFICATE_NUM: this.operatorCard,
                    LEGAL_PERSON_PHONE: this.operatorPhone,
                    LEGAL_PERSON_EMAIL: this.operatorEmail,
                    REGISTRATION_AUTHORITY: this.area[0] + this.area[1] + this.area[2] ? this.area[2] : '',
                    ESTABLISHED_TIME: this.zhuceriqi,
                    COMPOSITION_FORM: this.organization,
                    BUSINESS_PLACE: this.premises,
                    BUSINESS_SCOPE: this.businessScope,
                    COLLATERAL: this.limits,
                    COLLA_REMARK: this.limitsInfo,
                    REGIST_TYPE: 'I',
                    MEMBER_ID: this.userId,
                    IS_AUTHENTICATION: 'N',
                    tm: new Date().getTime()
                }).then(res => {
                    if (res.result === 'success') {
                        this.enterprise_id = res.ENTERPRISE_ID
                        this.step1 = false
                        this.step2 = true
                    } else {
                        this.$message.info('系统异常，请稍后重试')
                    }
                })
            }
        },
        onAgree (e) {
            if (e.length == 0) {
                this.isAgree = false
            } else {
                this.isAgree = true
            }
        },
        onSubmit () {
            if (this.fileList1.length == 0) {
                this.$message.info('请上传个体工商户信息查询授权书')
                return false
            } else if (this.fileList2.length == 0) {
                this.$message.info('请上传银行账务流水')
                return false
            } else if (this.fileListimg1.length == 0) {
                this.$message.info('请上传身份证正面')
                return false
            } else if (this.fileListimg2.length == 0) {
                this.$message.info('请上传身份证反面')
                return false
            } else if (this.fileListimg3.length == 0) {
                this.$message.info('请上传营业执照')
                return false
            } else if (!this.isAgree) {
                this.$message.info('请勾选协议！')
                return false
            }
            this.$message.success('提交成功，请耐心等待审核！')
        },
        getOrgDetail () {
            this.$store.dispatch('getOrgDetail', { ENTERPRISE_ID: this.orgId, tm: new Date().getTime() }).then(res => {
                if (res.result === 'success') {
                    if (res.pd !== null) {
                        let datas = res.pd
                        this.individualName = datas.ENTERPRISE_NAME
                        this.creditCode = datas.CREDIT_CODE
                        this.area = [datas.REGISAUTH_PROVINCE, datas.REGISAUTH_CITY, datas.REGISAUTH_DISTRICT ? datas.REGISAUTH_DISTRICT : '']
                        this.operatorName = datas.LEGAL_PERSON_NAME
                        this.operatorCard = datas.CERTIFICATE_NUM
                        this.operatorPhone = datas.LEGAL_PERSON_PHONE
                        this.operatorEmail = datas.LEGAL_PERSON_EMAIL
                        this.zhuceriqi = datas.ESTABLISHED_TIME
                        this.organization = datas.COMPOSITION_FORM
                        this.premises = datas.BUSINESS_PLACE
                        this.businessScope = datas.BUSINESS_SCOPE
                        this.limits = datas.COLLATERAL
                        this.limitsInfo = datas.COLLA_REMARK
                    }
                } else {
                    this.$message.error('服务器异常！')
                }
            })
        }
    },
    created () {
        if (this.orgId != null && this.orgId != '' && this.orgId != 'null' && this.orgId != undefined) {
            this.getOrgDetail()
        }
    }
})
</script>

<style lang="less" scoped>
.main-box{
    width: @main-width-base;
    margin:20px auto;
    .ec-box{
        padding: 15px;
        background-color: @color-ff;
        box-shadow: 0px 0px 10px #929292;
        border-radius: 5px;
        margin: 20px 0;
        position: relative;
        .title{
            font-size: @font-lg;
            padding: 0px 10px;
            padding-bottom: 15px;
            border-bottom: @border-base;
            display: flex;
            justify-content: space-between;
            align-items: center;
            p{
                margin-bottom: 0;
            }
        }
        .content{
            padding-top: 15px;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            .con-item{
                width: 49%;
            }
            .con-item-download{
                width: 100%;
                text-align: center;
                margin-bottom: 20px;
                a{
                    font-size: @font-lg;
                    display: inline-block;
                    text-decoration: underline;
                    color: @color-ff;
                    padding: 10px 60px;
                    background: -webkit-linear-gradient(to left,@color-blue,@color-purple); /* Safari 5.1-6.0 */
                    background: -o-linear-gradient(to left,@color-blue,@color-purple); /* Opera 11.1-12.0 */
                    background: -moz-linear-gradient(to left,@color-blue,@color-purple); /* Firefox 3.6-15 */
                    background: linear-gradient(to left,@color-blue,@color-purple); /* 标准语法 */


                }
            }
            .con-item-vercode{
                .ant-form-item{
                    .ant-input{
                        width: 55%;
                    }
                    .ant-btn{
                        width: 40%;
                        margin-left: 5%;
                    }
                }
            }
            .con-item-upload{
                margin: 0 150px;
                width: 25%;
                margin-bottom: 20px;
                ::v-deep(.ant-upload) {
                    width: 100%;
                }
                ::v-deep(.ant-btn){
                    width: 100%;
                }
            }
            .con-item-uploadimg{
                width: 30%;
                ::v-deep(.ant-upload){
                    width: 240px;
                    height: 195px;
                }
                ::v-deep(.ant-upload-list-item){
                    width: 240px;
                    height: 195px;
                }
                ::v-deep(.ant-upload-list-picture-card-container){
                    height: 195px;
                }
            }
        }
        .contents{
            text-align: center;
            ::v-deep(.ant-form-item-control-wrapper){
                margin: 0 auto;
                text-align: center;
            }
            .ant-btn{
                padding: 0 120px;
                height: 40px;
                color: @color-ff;
                background: -webkit-linear-gradient(to left,@color-blue,@color-purple); /* Safari 5.1-6.0 */
                background: -o-linear-gradient(to left,@color-blue,@color-purple); /* Opera 11.1-12.0 */
                background: -moz-linear-gradient(to left,@color-blue,@color-purple); /* Firefox 3.6-15 */
                background: linear-gradient(to left,@color-blue,@color-purple); /* 标准语法 */
            }
        }
    }
    .ec-box::before{
        content: '';
        position: absolute;
        left: -3px;
        top: 10px;
        width: 6px;
        height: 40px;
        background: -webkit-linear-gradient(to top,@color-blue,@color-purple); /* Safari 5.1-6.0 */
        background: -o-linear-gradient(to top,@color-blue,@color-purple); /* Opera 11.1-12.0 */
        background: -moz-linear-gradient(to top,@color-blue,@color-purple); /* Firefox 3.6-15 */
        background: linear-gradient(to top,@color-blue,@color-purple); /* 标准语法 */
    }
}
</style>
